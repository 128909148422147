import { createStore } from 'vuex'

import survey from "@/store/modules/survey";

export default createStore({
  state: {

  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
  modules: {
    survey
  }
})
