
const URL_BASE = process.env.VUE_APP_API_URL
const APP_URL = process.env.VUE_APP_APP_URL


export default {
    APP_URL: APP_URL,
    URL_API: URL_BASE+'/api',
    URL_AUTH: URL_BASE+'/oauth/',
}
