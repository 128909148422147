import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BaseLayout from "../views/BaseLayout";

const routes = [
  {
    path: '/:back_office/',
    name: 'survey',
    component: BaseLayout,
    redirect: {name: 'home'},
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: 'type',
        name: 'type',
        component: () => import(/* webpackChunkName: "about" */ '../views/TypeFormView.vue')
      },
      {
        path: 'comment',
        name: 'comment',
        component: () => import(/* webpackChunkName: "about" */ '../views/CommentView.vue')
      },
      {
        path: 'name',
        name: 'name',
        component: () => import(/* webpackChunkName: "about" */ '../views/NameView.vue')
      },
      {
        path: 'form',
        name: 'form',
        component: () => import(/* webpackChunkName: "about" */ '../views/FormView.vue')
      },
      {
        path: 'form/thank_you',
        name: 'form_thank_you',
        component: () => import(/* webpackChunkName: "about" */ '../views/FormThankYouView.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
