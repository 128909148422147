import config from "@/config/index";

const URL_BASE = config.URL_API


export default {
    namespaced: true,
    state: {
        type: null,
        comment: null,
        name: null,
        answers: [],
        questions: []
    },
    getters: {
        formatData(state){
             return{
                 'type': state.type.replace('m_',''),
                 'comment': state.comment,
                 'name': state.name,
                 'answers': state.answers,
             }
        },
        getQuestions(state){
            return state.questions
        }
    },
    mutations: {
        setType(state, value){
            state.type = value
        },
        setComment(state, value){
            state.comment = value
        },
        setName(state, value){
            state.name = value
        },
        addAnswer(state, value){
            state.answers.push(value)
        },
        clearContent(state){
            state.comment = null
            state.type = null
            state.answers = []
            state.name = null
        },
        setQuestions(state, value){
            state.questions = value
        }
    },
    actions: {
        save({ commit }, payload){
            return new Promise((resolve, reject)=>{
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                };

                fetch(URL_BASE+'/survey', options)
                    .then(response => response.json())
                    .then(response => resolve(response))
                    .catch(err => reject(err))
                    .finally(()=>{
                        commit('clearContent')
                    })
            })
        },
        getQuestionsServer({ commit }, payload){
            return new Promise((resolve, reject)=>{
                const requestOptions = {
                    method: "GET",
                    redirect: "follow"
                };

                fetch(URL_BASE+"/survey/getQuestions", requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        commit('setQuestions', result)
                        resolve(result)
                    })
                    .catch((error) => reject(error));
            })


        }
    },
};
