<template>
  <div class="home" @click="initForm">
    <img class="home__logo" src="../assets/image/logo_black.png" alt="Logo">
    <h1 class="home__title league_font">QUEREMOS CONOCER TU OPINIÓN</h1>
    <h2 class="home_back_office league_font">Sucursal: {{id?.name}} </h2>
    <img src="@/assets/image/bg_m_01.png" class="home__bottom_image" alt="">
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";

const router = useRouter();
const route = useRoute();
const store = useStore();

const BACK_OFFICES =

        [
          {"id":"1","name":"DRD3D LINDAVISTA"},
          {"id":"2","name":"DRD3D ROMA"},
          {"id":"3","name":"DRD3D DEL VALLE"},
          {"id":"4","name":"DRD3D POLANCO"},
          {"id":"5","name":"DRD3D ECATEPEC"},
          {"id":"6","name":"DRD3D INTERLOMAS"},
          {"id":"7","name":"DRD3D PEDREGAL"},
          {"id":"8","name":"DRD3D NEZA"},
          {"id":"9","name":"DRD3D IZTACALCO"},
          {"id":"10","name":"DRD3D COAPA"},
          {"id":"11","name":"DRD3D WEB"},
          {"id":"13","name":"DRD3D DEL VALLE 2"},
          {"id":"14","name":"DRD3D SATELITE"},
          {"id":"15","name":"DRD3D APP"},
          {"id":"16","name":"DRD3D TEPOZAN"},
          {"id":"84","name":"DRD3D PERDIDA"},
        ]



const id = computed(()=>{
  return BACK_OFFICES.find(x=>x.id === route.params.back_office )

})

const outClass = ref(false);
const initForm = () => {
  outClass.value = true;
  router.push({name: 'name'});
};

onMounted(() => {
  store.dispatch('survey/getQuestionsServer');
});
</script>

<style scoped lang="scss">
.home{

  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  background-image: url("@/assets/image/bg_01.png");
  background-size: cover;

  &__title{
    color: white;
    margin: 0;
  }
  &__logo{
    position: absolute;
  }
}

.home__bottom_image{
  display: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 7;
}
.home_back_office{
  position: absolute;
  color: #011689;
  bottom: 10px;
  margin: 0;
  left: 0;
  z-index: 100;
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .home{
    background: #011689;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .home__title{
    font-size: 5.5rem;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 10;
    padding-top: 40%;
  }
  .home__logo{
    width: 50%;
    top: 15px;
    left: 25%;
    z-index: 11;
  }
  .home__bottom_image{
    display: block;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

  .home{
    background-image: url("@/assets/image/bg_01.png");
    background-size: auto 105%;
    background-position: 40% 10%;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .home__title{
    font-size: 4.5rem;
    width: 55%;
    text-align: left;
    position: relative;
    z-index: 10;
    margin-left: 30px;
    margin-top: 30px;
  }
  .home__logo{
    width: 35%;
    top: 15px;
    left: 32%;
    z-index: 11;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .home_back_office{
    position: absolute;
    color: white;
    top: 10px;
    margin: 0;
    right: 10px;
    left: auto;
    z-index: 100;
    width: 40%;
    text-align: right;
  }
  .home__logo{
    width: 200px;
    top: 15px;
    left: 15px;
    z-index: 11;
  }
  .home{
    background-size: cover;
    justify-content: start;
  }
  .home__title{
    width: 50%;
    color: #ffffff;
    font-size: 6.2rem;
    padding-top: 0;
    padding-left: 5%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .home__logo{
    width: 250px;
    top: 15px;
    left: 15px;
    z-index: 11;
  }
  .home{
    background-size: cover;
    justify-content: start;
  }
  .home__title{
    width: 50%;
    color: white;
    font-size: 7.2rem;
    padding-top: 0;
    padding-left: 5%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .home__logo{
    width: 250px;
    top: 15px;
    left: 15px;
    z-index: 11;
  }
  .home{
    align-items: center;
    background-size: cover;
    justify-content: start;
  }
  .home__title{
    width: 50%;
    color: white;
    font-size: 8.2rem;
    padding-top: 0;
    padding-left: 5%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1380px) {
  .home__logo{
    width: 350px;
    top: 15px;
    left: 15px;
    z-index: 11;
  }
  .home{
    align-items: center;
    background-size: cover;
    justify-content: start;
  }
  .home__title{
    width: 50%;
    color: white;
    font-size: 9.2rem;
    padding-top: 0;
    padding-left: 5%;
  }
}


</style>
