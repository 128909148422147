<script setup>

</script>

<template>
  <div class="app_container">
    <router-view/>
  </div>
</template>

<style scoped lang="scss">
.app_container{
  width: 100vw;
  height: 100vh;
}
</style>
